<template>
  <v-card class="ma-3">
    <base-loading v-if="loading" />
    <div v-else class="content">
      <template>
        <v-card
          :class="{ 'card-success': cadastro, 'card-warn': !cadastro }"
          style="min-height: 60vh"
        >
          <h4 v-if="!cadastro" slot="header" class="card-title text-center">
            Edição de Percurso
          </h4>
          <h4 v-else slot="header" class="card-title text-center">
            Cadastro de Percurso
          </h4>
          <div class="clearfix" />
          <div>
            <v-form
              id="form"
              ref="form"
              v-model="valid"
              enctype="multipart/form-data"
              class="pa-3"
              lazy-validation
              @submit.prevent="action"
            >
              <v-card>
                <v-list-item-content>
                  <v-card-title class="headline mx-auto">
                    Dados do inicio da viagem
                  </v-card-title>
                  <v-row v-if="!loading" class="pa-3">
                    <v-col cols="6">
                      <v-autocomplete
                        v-model="form.consultor"
                        autocomplete="autocomplete_off_hack_xfr4!k"
                        label="Consultor *"
                        :items="consultor"
                        :rules="requiredRules"
                        item-text="Nome"
                        item-value="user_id"
                        :disabled="!loadingData.consultor"
                        :loading="!loadingData.consultor"
                        required
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-menu
                        v-model="menuDataInit"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            :value="moment(form.startDate).format('DD/MM/YYYY')"
                            label="Data de inicio"
                            prepend-icon="mdi-calendar"
                            color="teal"
                            readonly
                            :disabled="false"
                            v-on="on"
                          />
                        </template>
                        <v-date-picker
                          v-model="form.startDate"
                          style="margin: 0px"
                          :max="dataLimite"
                          color="teal"
                          locale="pt-br"
                          @input="menuDataInit = false"
                        />
                      </v-menu>
                      <!-- <v-text-field
                        v-model="form.startDate"
                        :value="
                          form.startDate
                            ? moment(form.startDate).format('DD/MM/YYYY')
                            : moment().format('DD/MM/YYYY')
                        "
                        label="Data de inicio"
                        prepend-icon="mdi-calendar"
                        color="teal"
                        readonly
                        v-on="on"
                      /> -->
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="form.startKilometers"
                        autocomplete="autocomplete_off_hack_xfr4!k"
                        item-text="text"
                        label="KM Inicial"
                        required
                      />
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="form.startLocationLatitude"
                        autocomplete="autocomplete_off_hack_xfr4!k"
                        item-text="text"
                        label="Latitude Inicial"
                        required
                      />
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="form.startLocationLongitude"
                        autocomplete="autocomplete_off_hack_xfr4!k"
                        item-text="text"
                        label="Longitude Inicial"
                        required
                      />
                    </v-col>
                    <v-card class="pa-0 px-5 pb-5 ma-2">
                      <v-card-title class="headline mx-auto ma-2 px-2">
                        Odômetro inicial
                      </v-card-title>
                      <crud-image-uploader
                        ref="form"
                        v-model="form.startImage"
                        dialog-title="Logotipo da Empresa"
                        :editable="editStartLogo"
                        no-data="img/no-avatar.jpg"
                        @delete="deleteStartCall"
                        @upload="editStartLogo = true"
                      />
                      <v-row justify="center">
                        <v-dialog
                          v-model="dialogRemoveStartLogo"
                          persistent
                          max-width="290"
                        >
                          <v-card>
                            <v-card-text
                              >Deseja remover o logotipo? Essa operação não
                              poderá ser desfeita!
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="green darken-1"
                                text
                                :disabled="buttonRemoveStartLogo"
                                @click="
                                  () => {
                                    form.startImage = tmpStartLogo
                                    dialogRemoveStartLogo = false
                                  }
                                "
                              >
                                Cancelar
                              </v-btn>
                              <v-btn
                                color="red darken-1"
                                text
                                :disabled="buttonRemoveStartLogo"
                                :loading="buttonRemoveStartLogo"
                                @click="
                                  () => {
                                    removeLogo()
                                  }
                                "
                              >
                                Remover
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-row>
                    </v-card>
                  </v-row>
                </v-list-item-content>
              </v-card>

              <v-card>
                <v-list-item-content>
                  <v-card-title class="headline mx-auto">
                    Dados do final da viagem
                  </v-card-title>
                  <v-row v-if="!loading" class="pa-3">
                    <v-col cols="6">
                      <v-menu
                        v-model="menuDataFim"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            :value="moment(form.endDate).format('DD/MM/YYYY')"
                            label="Data de encerramento"
                            prepend-icon="mdi-calendar"
                            color="teal"
                            readonly
                            v-on="on"
                          />
                        </template>
                        <v-date-picker
                          v-model="form.endDate"
                          style="margin: 0px"
                          :max="dataLimite"
                          color="teal"
                          locale="pt-br"
                          @input="menuDataFim = false"
                        />
                      </v-menu>
                    </v-col>
                    <v-col cols="6"></v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="form.endKilometers"
                        autocomplete="autocomplete_off_hack_xfr4!k"
                        item-text="text"
                        label="KM Final"
                        required
                      />
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="form.endLocationLatitude"
                        autocomplete="autocomplete_off_hack_xfr4!k"
                        item-text="text"
                        label="Latitude Final"
                        required
                      />
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="form.endLocationLongitude"
                        autocomplete="autocomplete_off_hack_xfr4!k"
                        item-text="text"
                        label="Longitude Final"
                        required
                      />
                    </v-col>
                    <v-card class="pa-0 px-5 pb-5 ma-2">
                      <v-card-title class="headline mx-auto ma-2 px-2">
                        Odômetro final
                      </v-card-title>
                      <crud-image-uploader
                        ref="form"
                        v-model="form.endImage"
                        dialog-title="Logotipo da Empresa"
                        :editable="editEndLogo"
                        no-data="img/no-avatar.jpg"
                        @delete="deleteEndCall"
                        @upload="editEndLogo = true"
                      />
                      <v-row justify="center">
                        <v-dialog
                          v-model="dialogRemoveEndLogo"
                          persistent
                          max-width="290"
                        >
                          <v-card>
                            <v-card-text
                              >Deseja remover o logotipo? Essa operação não
                              poderá ser desfeita!
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="green darken-1"
                                text
                                :disabled="buttonRemoveEndLogo"
                                @click="
                                  () => {
                                    form.endImage = tmpEndLogo
                                    dialogRemoveEndLogo = false
                                  }
                                "
                              >
                                Cancelar
                              </v-btn>
                              <v-btn
                                color="red darken-1"
                                text
                                :disabled="buttonRemoveEndLogo"
                                :loading="buttonRemoveEndLogo"
                                @click="
                                  () => {
                                    removeLogo()
                                  }
                                "
                              >
                                Remover
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-row>
                    </v-card>
                  </v-row>
                </v-list-item-content>
              </v-card>

              <v-btn
                type="submit"
                :disabled="salvando"
                :loading="salvando"
                :color="!$route.query.id ? 'success' : 'warning'"
                form="form"
              >
                {{ btnNome }}
              </v-btn>
              <v-btn
                :disabled="salvando"
                color="error"
                class="ml-3"
                @click="
                  () =>
                    this.$router.push({
                      path: `/${this.$user.get().role}/odometer`,
                    })
                "
              >
                Cancelar
              </v-btn>
            </v-form>
          </div>
        </v-card>
      </template>
    </div>
  </v-card>
</template>

<script>
import moment from 'moment'
import { _orderByName } from '../../../utils/formatter'

export default {
  data() {
    return {
      dialogRemoveStartLogo: false,
      buttonRemoveStartLogo: false,
      removedStartLogo: false,
      tmpStartLogo: '',
      editStartLogo: false,

      dialogRemoveEndLogo: false,
      buttonRemoveEndLogo: false,
      removedEndLogo: false,
      tmpEndLogo: '',
      editEndLogo: false,

      btnNome: !this.$route.query.id ? 'Salvar' : 'Salvar Alterações',

      salvando: false,
      loading: false,
      valid: false,
      cadastro: true,
      form: {
        consultor: null,
        startKilometers: '',
        endKilometers: '',
        startLocationLatitude: '',
        startLocationLongitude: '',
        endLocationLatitude: '',
        endLocationLongitude: '',
        startImageId: '',
        endImageId: '',
        startImage: '',
        endImage: '',
        startDate: this.moment().toISOString(true).substr(0, 10),
        endDate: this.moment().toISOString(true).substr(0, 10),
      },
      loadingData: {
        consultor: false,
      },
      consultor: [{ Nome: 'Carregando', id: 0 }],
      requiredRules: [v => !!v || '* Obrigatório'],

      dataFim: this.moment().toISOString(true).substr(0, 10),
      dataInicio: this.moment().toISOString(true).substr(0, 10),
      dataLimite: this.moment().toISOString(true).substr(0, 10),
      menuDataFim: false,
      menuDataIni: false,

      menuDataInit: false,

      requiredEndDateTime: [
        v =>
          this.validateEndTime(v) ||
          '* Data e hora final não pode ser menor que a inicial',
        v => !!v || '* Obrigatório',
      ],
    }
  },
  created() {
    this.cadastro = this.$route.query.id

    if (!this.$route.query.id) {
      //this.loadNewForm()
      this.loadConsultores()
    } else {
      //this.loadOldForm()
    }
  },
  methods: {
    getForm() {
      const data = {
        userId: this.form.consultor,
        startKilometers: this.form.startKilometers,
        endKilometers: this.form.endKilometers,
        startLocationLatitude: this.form.startLocationLatitude,
        startLocationLongitude: this.form.startLocationLongitude,
        endLocationLatitude: this.form.endLocationLatitude,
        endLocationLongitude: this.form.endLocationLongitude,
        startImageId: this.form.startImageId,
        endImageId: this.form.endImageId,
        startImage: this.form.startImage,
        endImage: this.form.endImage,
        startDate: this.form.startDate,
        endDate: this.form.endDate,
      }

      return data
    },

    action() {
      if (!this.$route.query.id) {
        this.create()
      } else {
        this.update()
      }
    },

    validateEndTime(v) {
      if (this.form.startDate || !v) return

      const startTime = moment()
      const endTime = moment(`${v} 23:59`, 'DD/MM/YYYY')

      if (startTime.isAfter(endTime)) {
        this.Toast().fire({
          icon: 'error',
          title: 'Data e hora final não pode ser menor que a inicial',
        })

        return false
      }

      return true
    },

    create() {
      this.salvando = true
      this.valid = this.$refs.form.validate()

      if (!this.valid) {
        this.Swal.fire(
          'Atenção',
          'Preencha os itens obrigatórios sinalizados com ("*").',
          'warning',
        )
        this.salvando = false
        return false
      }

      const data = this.getForm()

      try {
        this.api.save
          .odometer(data)
          .then(() => {
            this.Swal.fire({
              title: 'Sucesso',
              text: 'Cadastro Realizado',
              icon: 'success',
              showConfirmButton: false,
              timer: 2000,
            })
              .then(() => {
                this.$router.push({
                  path: `/${this.$user.get().role}/odometer`,
                })
              })
              .catch(() => {
                this.salvando = !this.salvando
              })
          })
          .catch(() => {
            this.salvando = !this.salvando
          })
      } catch (error) {
        this.salvando = false
      }
    },

    loadConsultores() {
      this.api.get.consultores(1).then(data => {
        this.consultor = data

        this.consultor = _orderByName({
          data,
          key: 'Nome',
        })

        if (this.consultor.length) {
          this.consultor.unshift({ Nome: '-- Selecione --', id: '0' })
        }

        this.loadingData.consultor = true
      })
    },

    deleteStartCall() {
      this.dialogRemoveStartLogo = true
      this.buttonRemoveStartLogo = false
    },
    deleteEndCall() {
      this.dialogRemoveEndLogo = true
      this.buttonRemoveEndLogo = false
    },
  },
}
</script>
